/* v-model="get_datetime" */
<template>
  <div class="compfecha">
    <v-dialog v-model="modal" width="290px">
      <template v-slot:activator="{}">
        
        <v-text-field
          v-bind="$input"
          v-model="dateFormatted"
          :label="schema.label"
          :disabled="!edicion"
          :append-icon="
            schema.comp.show !== undefined
              ? schema.comp.show
                ? icono
                : ''
              : icono
          "          
          @click:append="modal = !modal"
          @keydown="check_key"
          @keyup="fillDate"
          @blur="check_datetime"
          @change="changeFecha"
        >
        </v-text-field>
      </template>
      
      <template>
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="white"
            dark
            show-arrows
            hide-slider
            fixed-tabs
            active-class="activo"
            height="30px"
          >
            <v-tab v-for="seccion in get_sections" :key="seccion.titulo">
              <v-icon class="pr-2 black--text" small>{{
                seccion.icono
              }}</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Fecha -->
            <v-tab-item>
              <v-date-picker
                v-model="datepickerValue"
                locale="es-Es"
                :allowed-dates="allowed_dates"
                :min="min"
                :max="max"
                :first-day-of-week="1"
                :close-on-content-click="false"
                @input="schema.comp.format=='fh'? modal= false : ''">              
              </v-date-picker>

              <div class="conflex" style="justify-content:center;margin-bottom:10px">
                <v-btn text @click="fechaActual" >                
                  <h4 style="color:red">Fecha/Hora actual</h4>
                </v-btn>
              </div>              
            </v-tab-item>

            <!-- Hora -->
            <v-tab-item>
              <template v-if="!timeview">
                <v-row no-gutters class="justify-center">
                  <v-col md="2">
                    <v-btn icon @click="sumup_time('hora')">
                      <v-icon dark>mdi-chevron-up</v-icon>
                    </v-btn>

                    <v-text-field
                      v-bind="$input"
                      v-model="hora"
                      :tabindex="1"
                      @dblclick="open_timeview('hora')"
                    >
                    </v-text-field>

                    <v-btn icon @click="subtract_time('hora')">
                      <v-icon dark>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col md="2">
                    <v-btn icon @click="sumup_time('minutos')">
                      <v-icon dark>mdi-chevron-up</v-icon>
                    </v-btn>

                    <v-text-field
                      v-bind="$input"
                      v-model="minutos"
                      :tabindex="2"
                      @dblclick="open_timeview('minutos')"
                    >
                    </v-text-field>

                    <v-btn icon @click="subtract_time('minutos')">
                      <v-icon dark>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col md="2">
                    <v-btn icon @click="sumup_time('segundos')">
                      <v-icon dark>mdi-chevron-up</v-icon>
                    </v-btn>

                    <v-text-field
                      v-bind="$input"
                      v-model="segundos"
                      :tabindex="3"
                      @dblclick="open_timeview('segundos')"
                    >
                    </v-text-field>

                    <v-btn icon @click="subtract_time('segundos')">
                      <v-icon dark>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <template v-else>
                <v-row
                  no-gutters
                  v-for="x in items_timeview.length / 4"
                  :key="x"
                  class="justify-center"
                >
                  <v-btn
                    v-for="i in 4"
                    :key="i"
                    icon
                    class="pl-7 pr-7 mr-1 mb-1"
                    @click="click_onTime(items_timeview[4 * x - (4 - i) - 1])"
                  >
                    {{ items_timeview[4 * x - (4 - i) - 1] }}
                  </v-btn>
                </v-row>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {    
    schema: { type: Object, default:null },
    data: { type:Object, default:undefined },
    data_fieldName: { type: String, default: '' },
    edicion: { type: Boolean, default: false },
    icono: { type: String, default: "mdi-calendar-range" },
    /* sep: { type:String, default:'/' }, */
    dates: { type: Array, default: () => [] },
    min: { type: String, default: "" },
    max: { type: String, default: "" }
  },

  data() {
    return {
      dateFormatted: "",
      modal: false,
      tab: null,
      timeview: false,
      selected_timeview: null,
      items_timeview: [],
      items_hora: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23"
      ],
      item_minseg: [
        "00",
        "05",
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55"
      ],
      hora: "00",
      minutos: "00",
      segundos: "00",
      max_hora: "23",
      max_minutos: "59",
      max_segundos: "59",
      min_hora: "00",
      min_minutos: "00",
      min_segundos: "00"
    };
  },

  created() {    
    this.set_value();   
  },

  computed: {
    get_sections() {
      if (this.schema.comp.format !== "fhhr")
        return [{ titulo: "Fecha", icono: "mdi-calendar" }];
      return [
        { titulo: "Fecha", icono: "mdi-calendar" },
        { titulo: "Hora", icono: "mdi-timer-outline" }
      ];
    },

    // get(): obtengo la fecha del datepicker
    // set(): al pulsar en una fecha del datepicker, guardo la fecha o fecha/hora en el schema
    datepickerValue: {
      get() {          
          return this.get_value();          
      },

      set(val) {       
          if (this.schema.comp.format !== "fhhr") return this.set_value(val);
          return this.set_value(val + " " + this.hora + ":" + this.minutos + ":" + this.segundos);     
      }
    }

  },

  methods: {
    // devuelvo la fecha actual en formato base datos
    actualDate() {
      const fecha = new Date();
      return `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(fecha.getDate()).padStart(2, "0")}`;
    },

    // devuelvo la fecha/hora actual en formato base datos
    actualDateTime() {
      const fecha = new Date();      
      return `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")}:${String(fecha.getSeconds()).padStart(2, "0")}`;
    },

    // formateo fecha - fecha/hora de base de datos a visual
    formatDate(datetime) {
      if (!datetime) return "";

      const [sdate, stime] = datetime.split(" ");
      const sep = this.$store.state.separador_fecha;
      const [ano, mes, dia] = sdate.split("-");

      if (this.schema.comp.format === "fh")
        return `${dia.padStart(2, "0")}${sep}${mes.padStart(
          2,
          "0"
        )}${sep}${ano}`;
      return `${dia.padStart(2, "0")}${sep}${mes.padStart(
        2,
        "0"
      )}${sep}${ano} ${stime}`;
    },

    // formateo fecha de visual a base de datos
    parseDate(date) {
      if (!date) return "";

      const [day, month, year] = date.split(this.$store.state.separador_fecha);
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    // relleno con separadores al introducir la fecha manualmente
    fillDate(e) {
      var key = e.keyCode;
     
      if (key === 32) return;
      if ((this.dateFormatted.length === 2 || this.dateFormatted.length === 5) && key!= 8)
        this.dateFormatted += this.$store.state.separador_fecha;
      if (this.dateFormatted.length === 10) this.dateFormatted += " ";
      if (this.dateFormatted.length === 13 || this.dateFormatted.length === 16)
        this.dateFormatted += ":";
    },

    // sólo permito introducir números o varias teclas especiales
    check_key(e) {
      const key = e.keyCode;
      const nallowed = [8, 9, 37, 39, 46];

      if (nallowed.indexOf(key) >= 0) return;
      if (key < 48 || (key > 57 && key < 96) || key > 105 || key === 32) {
        e.preventDefault();
        return;
      }
      if (
        this.schema.comp.format === "fh" &&
        this.dateFormatted.length + 1 > 10
      ) {
        e.preventDefault();
        return;
      }
      if (this.dateFormatted.length + 1 > 19) {
        e.preventDefault();
        return;
      }
    },

    // chequeo fecha introducida y la complemento si hace falta
    check_datetime() 
    {      
        if (this.dateFormatted== "" || this.dateFormatted== null) return;
        this.set_value(this.validate_date(this.dateFormatted));       
    },

    // valido fecha recibida
    validate_date(date) {
      if (date === "" || date === null) date = this.formatDate();

      const fecha = new Date();
      const sep = this.$store.state.separador_fecha;
      const [sdate, stime] = date.split(" ");

      // obtengo la fecha introducida y la complemento si hace falta
      var [dia, mes, ano] = sdate.split(sep);
      dia = dia.padStart(2, "0");
      mes =
        mes !== undefined && mes !== ""
          ? mes.padStart(2, "0")
          : String(fecha.getMonth() + 1).padStart(2, "0");
      ano =
        ano !== undefined && ano !== ""
          ? ano.length < 4
            ? fecha.getFullYear()
            : ano
          : fecha.getFullYear();

      // valido fecha
      if (this.schema.comp.format === "fh") {
        if (isNaN(new Date(`${ano}${sep}${mes}${sep}${dia}`)))
          return this.actualDate();
        return `${ano}-${mes}-${dia}`;
      }

      // obtengo la hora introducida y la complemento si hace falta
      var [hora, min, seg] = stime
        ? stime.split(":")
        : `${fecha.getHours()}:${fecha.getMinutes()}:${fecha.getSeconds()}`.split(
            ":"
          );
      hora = hora.padStart(2, "0");
      min =
        min !== undefined && min !== ""
          ? min.padStart(2, "0")
          : String(fecha.getMinutes()).padStart(2, "0");
      seg =
        seg !== undefined && seg !== ""
          ? seg.padStart(2, "0")
          : String(fecha.getSeconds()).padStart(2, "0");

      // valido fecha/hora
      if (
        isNaN(new Date(`${ano}${sep}${mes}${sep}${dia} ${hora}:${min}:${seg}`))
      )
        return this.actualDateTime();
      return `${ano}-${mes}-${dia} ${hora}:${min}:${seg}`;
    },

    allowed_dates: function(val) {
      if (this.dates.length === 0) return true;
      return this.dates.indexOf(val) !== -1;
    },

    // sumo 1 a la hora, minutos o segundos y actualizo el valor del schema
    sumup_time(tipo) {
      const val =
        this[tipo] + 1 > this["max_" + tipo]
          ? this["min_" + tipo]
          : String(Number(this[tipo]) + 1);
      this[tipo] = val.padStart(2, "0");

      this.update_datetime();
    },

    // resto 1 a la hora, minutos o segundos y actualizo el valor del schema
    subtract_time(tipo) {
      const val =
        this[tipo] - 1 < this["min_" + tipo]
          ? this["max_" + tipo]
          : String(this[tipo] - 1);
      this[tipo] = val.padStart(2, "0");

      this.update_datetime();
    },

    // actualizo la fecha/hora que tenemos en el schema
    update_datetime() 
    {    
        let [sdate] = this.schema.value.split(" ");

        if (sdate === "") sdate = this.actualDate();
        this.set_value(`${sdate} ${this.hora}:${this.minutos}:${this.segundos}`);
        //this.schema.value = `${sdate} ${this.hora}:${this.minutos}:${this.segundos}`;
        //this.dateFormatted = this.formatDate(this.schema.value);
    },

    open_timeview(tipo) {
      this.timeview = !this.timeview;
      this.items_timeview =
        tipo === "hora" ? this.items_hora : this.item_minseg;
      this.selected_timeview = tipo;
    },

    set_time(time) {
      const [hora, minutos, segundos] = time === undefined ? "00:00:00".split(":") : time.split(":");

      this.hora = hora;
      this.minutos = minutos;
      this.segundos = segundos;
    },

    // actualizo hora, min, seg pulsado y cierro timeview
    click_onTime(time) {
      this[this.selected_timeview] = time;
      this.timeview = !this.timeview;

      this.update_datetime();
    },

    //
    get_value()
    {
        var data= this.data!== undefined? this.data[this.data_fieldName] : this.schema.value;

        if (!data) return;
       
        const [sdate, stime] = data.split(" ");
        if (this.schema.comp.format=== "fhhr") this.set_time(stime);
        return sdate.trim();
    },

    //
    set_value(val= undefined)
    {     
        if (this.data!== undefined) {
            if (val=== undefined) val= this.data[this.data_fieldName];

            this.dateFormatted = this.formatDate(val);
            this.data[this.data_fieldName]= val;
            return;
        }
        
        if (val=== undefined) val= this.schema.value;
        this.dateFormatted = this.formatDate(val);        
        this.schema.value= val;
    },


    //
    changeFecha() {    
      if (this.dateFormatted== "" || this.dateFormatted== null) this.schema.value= "";      
    },


    // 
    fechaActual() {      
      this.datepickerValue= this.schema.comp.format== "fh"? this.actualDate() : this.actualDateTime();
      this.modal= false;
    }

  },

  watch: {
    // si cambia la fecha, configuro su valor
    'schema.value': { 
      deep:true,
      handler() 
      {   
        //alert('cambio schema');      
        this.set_value();        
      }
    },

    /* dateFormatted() {
      this.set_value();  
      //alert('cambio dateFormatted');
    } */
  }

};
</script>

<style scoped>
.activo {
  background-color: lightblue;
}
</style>
